<template>
  <div>
    <AsrModelSelector
      ref="speech-recognition-model"
      v-model="selectedAsrModel"
      :model-filter="asrModelFilter"
      @input="inputAsrModel"
    />
    <div class="info">
      <div class="info-wrapper">
        <!-- eslint-disable-next-line -->
        {{ __("Depending on Language selection, some models may not be available.") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AsrModelSelector from "@/components/AsrModelSelector";

export default {
  components: {
    AsrModelSelector
  },
  props: {
    /**
     * The ASR model to be used for this node
     */
    asrModel: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Available ASR model for the current language. Empty array will show all ASR models
     */
    asrModelFilter: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),

    selectedAsrModel: {
      get() {
        return this.asrModel;
      },
      set(value) {
        this.$emit("update-asr-model", value);
      }
    }
  },

  methods: {
    inputAsrModel(val) {
      this.$emit("update-asr-model", val);
    }
  },

  watch: {}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.info-wrapper {
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: 13px;
  margin-bottom: 2px;
  margin-top: 5px;
  letter-spacing: 0.005rem;
}
</style>
