<template>
  <input-variable-popper
    ref="asr-model-selector"
    v-model="selectedModel"
    :is-text-area="false"
    force-reinitialize
    is-auto-complete-input
    :autocomplete-list="availableModels"
    :placeholder="__('Enter your text')"
  />
</template>

<script>
import * as AsrModels from "@/utils/AsrModels";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";

export default {
  components: { InputVariablePopper },
  props: {
    value: {
      required: true
    },

    /**
     * What models to display in the picker. Empty array will show all models
     */
    modelFilter: {
      type: Array,
      default: () => [],
      required: false
    }
  },

  computed: {
    /**
     * The currently selected model
     */
    selectedModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    /**
     * The available models to display
     */
    availableModels() {
      return Array.isArray(this.modelFilter) && this.modelFilter.length >= 1
        ? AsrModels.whereTypeIs(this.modelFilter)
        : AsrModels.models;
    }
  }
};
</script>
